
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed,PushNotificationSchema,PushNotifications, Token} from '@capacitor/push-notifications';
import { AuthService } from './auth.service';
import { BadgeService } from './badge.service';
import { ApiClientService } from './swagger';
import { Condominium, LoginDataResponse} from './swagger/models';

@Injectable({
  providedIn: 'root'
})
export class PushnotificationService {

  private registered = false;
  private condominium: Condominium;
  private user: LoginDataResponse;
  private notificationRoutes = {
    1: "news",
    2: "event",
    3: "voting",
    4: "pay",
    5: "directory",
    6: "reservations",
    7: "invitation",
    8: "", // hay un bug que no se actualiza la segunda tab
    9: "emergency",
    10: "statement",
    11: "qr",
    12: "finance",
    13: "documents"
  };


  constructor(private apiClient: ApiClientService,
      private badgeService: BadgeService,
      private authService: AuthService,
      private router: Router,
    ) {

      this.authService.user.subscribe(data => {
        this.user = data;
      });

      this.authService.condominium.subscribe(data => {
        this.condominium = data;
      });


    }

  public init(){

    if(Capacitor.getPlatform() !== 'web' && !this.registered ){

      this.registerNotifications();
    }
  }

  public desregister() {
    if(Capacitor.getPlatform() !== 'web' ){
      this.registered = false;
      this.badgeService.removeAll();
      PushNotifications.removeAllListeners();

    }
  }

  private async  registerNotifications(){
    this.registered = true;

    PushNotifications.requestPermissions().then(result => {

      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
         PushNotifications.register();
      } else {
        // Show some error

      }
    });

      PushNotifications.addListener('registration',
      (token: Token) => {
        console.log("entro a registrar");
        console.log(token.value);

        // save token in service frakcio
        this.apiClient.savemobiletoken(this.user.token, {
          mobile_token: token.value,
          type: Capacitor.getPlatform()
        }).subscribe(data => {
          console.log("token dispositivo enviado a servicio");
        }, error => {
          console.log("error al enviar token a ws");
        });

      }
    );


    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
       // alert('Push received: ' + JSON.stringify(notification));

        console.log("recibe notificación");

        // se cambia el servicio de badge para globos internos
        this.badgeService.add(Number(notification.data.type), Number(notification.data.idCondominium), Number(notification.data.id) );

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // aqui llega cuando le dan clic

        let idCondominium = Number(notification.notification.data.idCondominium);
        const type: number = notification.notification.data.type;

        if(this.condominium?.id !== idCondominium){
          let condominium = this.user?.condominiums?.find(e=>e.id === idCondominium);
          if(condominium)
            this.authService.setCondominium(condominium);

        }
        console.log("evaluamos");


        // type es equivalente al id del Modulo
        let urlTo = "/"
        if(this.checkModule(type)){
          urlTo += this.notificationRoutes[type];
        }

        console.log('la url ' + urlTo);
        console.log('el id: ' + notification.notification.data.id);

        this.router.navigate([urlTo, {
          'id': notification.notification.data.id
        }]);


      }
    );

  }

  private checkModule(type: number) : boolean{
    return this.condominium.modules_app.filter(e=>e.id == type).length > 0;
  }


}
